<template>
    <div class="load" ref="load">
        <div v-show="loading">
            <el-divider class="loading">
                <!-- <i class="el-icon-loading"></i> -->
                <!-- <span>数据加载中...</span> -->
                <div class="rectangle">
                    <div></div>
                </div>
            </el-divider>
        </div>
        <!-- - 暂时先到这里吧 - -->
        <span v-show="endtxt">- 暂时先到这里吧 -</span>
    </div>
</template>

<script>
export default {
    props: {
        callBack: {
            // 出现在视图的回调
            type: Function,
        },
    },
    data() {
        return {
            loading: false,
            // observer: {},
            endtxt: false,
        };
    },
    mounted() {
        this.obs();
        // 初始开启观察
        this.observer.observe(this.$refs.load);
    },
    methods: {
        // 观察
        obs() {
            let _this = this;
            // 开启观察
            function start() {
                _this.observer.observe(_this.$refs.load);
                _this.loading = false;
            }
            // 删除观察
            function remove() {
                _this.observer.disconnect();
                // _this.observer = null;
                _this.endtxt = true;
                _this.loading = false;
            }
            // 重置
            function resize() {}
            _this.observer = new IntersectionObserver(entries => {
                // 观察元素出现在视图后
                if (entries[0].isIntersecting) {
                    _this.loading = true;
                    _this.observer.unobserve(_this.$refs.load);
                    _this.callBack(start, remove);
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.load {
    width: 100%;
    min-height: 30px;
    i {
        color: #7781f1;
    }
    span {
        color: #7781f1;
    }
}
.rectangle {
    width: 5px;
    height: 30px;
    position: relative;
    & > div {
        margin: 0 auto;
        width: 5px;
        height: 35px;
        background-color: #7781f1;
        animation: an infinite 1s ease-in-out -0.2s;
        position: absolute;
        &::before,
        &::after {
            content: " ";
            position: absolute;
            width: 5px;
            height: 20px;
            background-color: #7781f1;
        }
        &::before {
            left: -10px;
            animation: an infinite 1s ease-in-out -0.4s;
        }
        &::after {
            right: -10px;
            animation: an infinite 1s ease-in-out;
        }
        @keyframes an {
            0%,
            100%,
            80% {
                height: 20px;
                box-shadow: 0 0 #fff;
                top: 50%;
                transform: translateY(-50%);
            }
            40% {
                height: 30px;
                box-shadow: 0 -20px #fff;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}
</style>
