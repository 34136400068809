<template>
    <!-- 瀑布流布局组件 -->
    <div class="main">
        <div class="column" v-for="el in arr" :key="el.id" :style="{ width: `${100 / columnCount}%` }">
            <div>
                <img src="" alt="" />
                <div v-for="(e, index) in el.children" :key="e.id">
                    <slot :e="e" :index="index"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // 列表(异步数据)
        list: {
            type: Array,
            default: [],
        },
        // 指定列数
        columnCount: Number,
    },
    computed: {
        arr() {
            if (this.list.length == 0) return [];
            let arr = [];
            for (let i = 0; i < this.columnCount; i++) {
                arr.push({
                    h: 0,
                    id: Math.random(),
                    children: [],
                });
            }
            this.list.forEach(ele => {
                // 查找最小高度的
                let min = Math.min(...arr.map(e => e.h));
                // 找到
                let obj = arr[arr.findIndex(e => e.h == min)];
                obj.children.push(ele);
                obj.h += ele.hw;
            });
            return arr;
        },
    },
};
</script>

<style lang="less" scoped>
.main {
    width: 100%;
    .column {
        display: inline-block;
        & > div {
            width: 100%;
            display: flex;
            flex-direction: column;
            // align-content: center;
            align-items: center;
            & > div {
                width: 100%;
            }
        }
    }
}
</style>
