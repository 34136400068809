<template>
    <div class="searchPage">
        <div class="dlBox">
            <!-- 顶部tab -->
            <div class="dlTabs">
                <div
                    class="dt1"
                    v-for="el in navList"
                    :key="el.name"
                    @click="activeName = el.name"
                    :class="activeName == el.name ? 'dtActive' : ''"
                    v-show="el.count != 0"
                >
                    {{ el.name }}
                </div>
            </div>
            <!-- 内容 -->
            <div class="contentList">
                <template v-for="el in navList">
                    <component
                        v-show="activeName == el.name"
                        :key="el.name"
                        :list="el.list"
                        :is="el.component"
                        :callBack="el.callBack"
                        :itemClick="el.itemClick ? el.itemClick : ''"
                    ></component>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import ListCase from "./SearchPage/ListCase.vue";
import list from "@/components/content/Movement/list.vue";
import ListPartners from "./SearchPage/ListPartners.vue";

export default {
    name: "SearchPage",
    data() {
        return {
            activeName: "",
            navList: [
                {
                    key: "project",
                    name: "客户案例",
                    // 第xx页
                    page: 1,
                    // 每页xx条
                    num: 10,
                    // 总条数
                    count: 0,
                    list: [],
                    component: ListCase,
                    callBack: (start, remove) => {
                        let title = "客户案例";
                        let obj = this.navList.find(e => e.name == title);
                        obj.page++;
                        let keyword = this.$route.query.val;
                        let { page, num } = obj;
                        axios.get("/api/project/fivekeyword", { params: { keyword, page, num, title } }).then(({ data }) => {
                            obj.list = [...obj.list, ...data];
                            this.$nextTick(() => {
                                start();
                                if (obj.list.length >= obj.count) {
                                    remove();
                                }
                            });
                        });
                    },
                },
                {
                    key: "article",
                    name: "威有动静",
                    page: 1,
                    num: 10,
                    count: 0,
                    list: [],
                    component: list,
                    callBack: (start, remove) => {
                        let title = "威有动静";
                        let obj = this.navList.find(e => e.name == title);
                        obj.page++;
                        let keyword = this.$route.query.val;
                        let { page, num } = obj;
                        axios.get("/api/project/fivekeyword", { params: { keyword, page, num, title } }).then(({ data }) => {
                            obj.list = [...obj.list, ...data];
                            this.$nextTick(() => {
                                start();
                                if (obj.list.length >= obj.count) {
                                    remove();
                                }
                            });
                        });
                    },
                    itemClick: el => this.$router.push("/movementDetail/" + el.id),
                },
                {
                    key: "solution",
                    name: "解决方案",
                    page: 1,
                    num: 10,
                    count: 0,
                    list: [],
                    component: list,
                    callBack: (start, remove) => {
                        let title = "解决方案";
                        let obj = this.navList.find(e => e.name == title);
                        obj.page++;
                        let keyword = this.$route.query.val;
                        let { page, num } = obj;
                        axios.get("/api/project/fivekeyword", { params: { keyword, page, num, title } }).then(({ data }) => {
                            obj.list = [...obj.list, ...data];
                            this.$nextTick(() => {
                                start();
                                if (obj.list.length >= obj.count) {
                                    remove();
                                }
                            });
                        });
                    },
                    itemClick: el => this.$router.push("/movementDetail/" + el.id),
                },
                {
                    key: "share",
                    name: "分享机遇",
                    page: 1,
                    num: 10,
                    count: 0,
                    list: [],
                    component: ListPartners,
                    callBack: (start, remove) => {
                        let title = "分享机遇";
                        let obj = this.navList.find(e => e.name == title);
                        obj.page++;
                        let keyword = this.$route.query.val;
                        let { page, num } = obj;
                        axios.get("/api/project/fivekeyword", { params: { keyword, page, num, title } }).then(({ data }) => {
                            obj.list = [...obj.list, ...data];
                            this.$nextTick(() => {
                                start();
                                if (obj.list.length >= obj.count) {
                                    remove();
                                }
                            });
                        });
                    },
                },
                {
                    key: "partner",
                    name: "合作伙伴",
                    page: 1,
                    num: 10,
                    count: 0,
                    list: [],
                    component: ListPartners,
                    callBack: (start, remove) => {
                        let title = "分享机遇";
                        let obj = this.navList.find(e => e.name == title);
                        obj.page++;
                        let keyword = this.$route.query.val;
                        let { page, num } = obj;
                        axios.get("/api/project/fivekeyword", { params: { keyword, page, num, title } }).then(({ data }) => {
                            obj.list = [...obj.list, ...data];
                            this.$nextTick(() => {
                                start();
                                if (obj.list.length >= obj.count) {
                                    remove();
                                }
                            });
                        });
                    },
                },
            ],
        };
    },
    watch: {
        "$route.query.val": {
            handler(newVal) {
                if (newVal == "") return this.$router.replace("/home");
                axios.get("/api/project/fivekeyword", { params: { keyword: newVal } }).then(({ data }) => {
                    this.navList.forEach(e => {
                        e.count = data[e.name].count;
                        e.list = data[e.name].data;
                        e.page = 1;
                    });
                    this.activeName = this.navList.find(e => e.count != 0).name;
                });
            },
            immediate: true,
        },
    },
};
</script>

<style lang="less" scoped>
.searchPage {
    width: 100%;
    min-height: 900px;
    margin: 0 auto;
    position: relative;
    .dlBox {
        // width: 95vw;
        padding: 0 20px;
        height: 100%;
        margin: 0 auto;
        margin-top: 100px;
        // 顶部tabs
        .dlTabs {
            height: 50px;
            margin: 0 auto;
            display: flex;
            // justify-content: space-evenly;
            .dt1 {
                width: 150px;
                height: 40px;
                margin-right: calc((100% - (5 * 150px)) / 5);
                line-height: 40px;
                border-radius: 20px;
                text-align: center;
                box-shadow: 1px 1px 10px 1px gainsboro;
                color: #7781f1;
                font-weight: 900;
                cursor: pointer;
            }
            .dtActive {
                background: linear-gradient(to right, #7781f1, #7781f1e0);
                color: white;
            }
        }
        .contentList {
            padding: 10px;
        }
    }
    .divider {
        position: absolute;
        bottom: 0px;
    }
}
</style>
