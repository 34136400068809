<template>
    <div class="p2b2">
        <ul v-if="list.length">
            <li class="p2Li" v-for="item in list" :key="item.id" @click="goDetails(item)">
                <img v-if="item.image" :src="item.image" class="p2Bg" />
                <div class="plText">
                    <div class="ptIcon">
                        <img :src="item.icon" />
                    </div>
                    <p class="ptp">{{ item.title }}</p>
                </div>
                <div class="clMask" v-if="item.qrcode">
                    <img :src="item.qrcode" class="cmCode" />
                </div>
            </li>
        </ul>

        <img v-if="!once" src="https://iv.okvu.cn/vugw/img/vujzz.png" style="width: 20%" />
        <img v-if="list.length == 0 && once" src="https://iv.okvu.cn/vugw/img/zwsj.png" style="width: 20%" />
        <lazy-loading v-if="list.length != 0 && once" :callBack="callBack"></lazy-loading>
    </div>
</template>

<script>
import LazyLoading from "@/util/LazyLoading.vue";

export default {
    components: { LazyLoading },
    props: {
        list: {
            type: Array,
            require: true,
        },
        callBack: [Function],
    },
    data() {
        return {
            once: false,
        };
    },
    methods: {
        goDetails({ id, details, url, type }) {
            // 跳链接
            if (details == "" || details == "<p><br></p>" || details == null) {
                if (!url) return;
                if (url.indexOf("http") >= 0 || url.indexOf("https") >= 0) {
                    window.open(url);
                } else {
                    window.open("http://" + url);
                }
                return;
            }
            // 跳详情
            if (type == 1) {
                this.$router.push("/partner3Details?id=" + id);
            } else if (type == 2) {
                this.$router.push("/partner2Details?id=" + id);
            }
        },
    },
    watch: {
        list() {
            this.once = true;
        },
    },
};
</script>

<style lang="less" scoped>
.p2b2 {
    width: 100%;
    overflow: hidden;
    ul {
        width: 100%;
        // min-height: 1080px;
        margin-top: 20px;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-content: flex-start;
        .p2Li {
            width: 28%;
            height: 195px;
            position: relative;
            background-color: #fff;
            border-radius: 20px;
            overflow: hidden;
            box-shadow: 1px 1px 10px 1px gainsboro;
            cursor: pointer;
            transition: 0.5s;
            text-align: left;
            margin-bottom: 50px;
            .p2Bg {
                position: absolute;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .plText {
                width: 105px;
                height: 36px;
                position: absolute;
                bottom: 25px;
                left: 40px;
                .ptIcon {
                    width: 36px;
                    height: 36px;
                    float: left;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
                .ptp {
                    width: 54px;
                    height: 36px;
                    float: left;
                    font-size: 18px;
                    margin-left: 10px;
                    line-height: 36px;
                    white-space: nowrap;
                }
            }
            .clMask {
                width: 100%;
                height: 195px;
                background: rgba(0, 0, 0, 0.5);
                opacity: 0;
                text-align: center;
                border-radius: 10px;
                position: absolute;
                transition: 0.5s;
                top: 0px;
                .cmCode {
                    width: 100px;
                    height: 100px;
                    position: relative;
                    top: 50%;
                    transform: translateY(-50%);
                    // margin-top: 20px;
                    // border-radius: 20px;
                }
            }
        }

        .p2Li:hover {
            transform: scale(1.1);
            .clMask {
                opacity: 1;
            }
        }
    }
}
</style>
