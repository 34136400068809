<template>
    <div class="main">
        <vue-waterfall :list="list" :columnCount="6" ref="vueWaterfall">
            <template slot-scope="{ e }">
                <div class="anli" @click="goDetail(e)">
                    <img v-lazy="e.little_image" class="little_image" />
                    <img :src="e.size_image" class="sizeImg" :style="`${e.size_position}:0px`" />
                    <div class="zz" v-if="e.qrcode || e.logo">
                        <img v-if="e.qrcode" :src="e.qrcode" />
                        <img v-else :src="e.logo" />
                    </div>
                </div>
            </template>
        </vue-waterfall>
        <!-- 暂无数据 -->
        <img v-if="!once" src="https://iv.okvu.cn/vugw/img/vujzz.png" style="width: 20%" />
        <img v-if="list.length == 0 && once" src="https://iv.okvu.cn/vugw/img/zwsj.png" style="width: 20%" />
        <lazy-loading v-if="list.length != 0 && once" :callBack="callBack"></lazy-loading>
    </div>
</template>

<script>
import VueWaterfall from "@/util/vue-waterfall.vue";
import LazyLoading from "@/util/LazyLoading.vue";
export default {
    components: { VueWaterfall, LazyLoading },
    props: {
        list: {
            type: Array,
            default: [],
        },
        callBack: {
            type: Function,
        },
    },
    data() {
        return {
            once: false,
        };
    },
    methods: {
        goDetail({ id, ueditorData, project_url }) {
            if (ueditorData == "" || ueditorData == "<p><br></p>" || ueditorData == null) {
                if (!project_url) return;
                if (project_url.indexOf("http") >= 0 || project_url.indexOf("https") >= 0) {
                    window.open(project_url);
                } else {
                    window.open("http://" + project_url);
                }
                return;
            }
            this.$router.push({
                name: "listDetail",
                params: { id },
            });
        },
    },
    watch: {
        list(newVal) {
            // console.log(newVal);
            this.once = true;
        },
    },
};
</script>

<style lang="less" scoped>
.main {
    width: 100%;
    .anli {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 10px;
        transition: 0.5s;
        overflow: hidden;
        border-radius: 10px;
        box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 10px 1px;
        position: relative;
        cursor: pointer;
        .little_image {
            width: 100%;
            margin-bottom: -2%;
            object-fit: cover;
        }
        .sizeImg {
            position: absolute;
            bottom: 0px;
            max-width: 50%;
        }
        &:hover {
            transform: translateY(-5px);
            .little_image {
                position: relative;
            }
            .zz {
                opacity: 1 !important;
            }
        }
        .zz {
            width: 100%;
            height: 100%;
            position: absolute;
            background: rgba(0, 0, 0, 0.7);
            z-index: 10;
            display: flex;
            flex-direction: column;
            align-items: center;
            transition: 0.5s;
            opacity: 0;
            top: 0;
            justify-content: center;
            color: white;
            font-size: 24px;
            & > img {
                max-height: 60%;
                max-width: 60%;
            }
        }
    }
}
</style>
