<template>
    <div class="list" :style="list.length == 0 ? 'text-align: center' : ''">
        <div v-for="el in list" :key="el.id" @click="itemClick(el)" v-show="el.status">
            <img v-lazy="el.little_image" />
            <div>
                <p class="title">{{ el.title }}</p>
                <p class="intro">{{ el.intro }}</p>
                <div class="info">
                    <div>
                        查看详情
                        <div class="arrowRight">
                            <img src="https://iv.okvu.cn/vugw/img/vu818.png" />
                        </div>
                    </div>

                    <div>
                        <span>{{ el.create_time }}&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <div class="yan">
                            <img src="http://iv.okvu.cn/vugw/img/vu818.png" />
                        </div>
                        <span>{{ el.look_nums }}</span>
                    </div>
                </div>
            </div>
        </div>
        <img v-if="!once" style="width: 20%" src="https://iv.okvu.cn/vugw/img/vujzz.png" />
        <img v-if="list.length == 0 && once" style="width: 20%" src="https://iv.okvu.cn/vugw/img/zwsj.png" />
        <lazy-loading v-if="list.length != 0 && once" :callBack="callBack"></lazy-loading>
    </div>
</template>

<script>
import LazyLoading from "@/util/LazyLoading.vue";

export default {
    components: { LazyLoading },
    props: {
        list: {
            type: Array,
            require: true,
        },
        itemClick: [Function, String],
        callBack: {
            type: Function,
        },
    },
    data() {
        return {
            once: false,
        };
    },
    methods: {},
    watch: {
        list(val) {
            // 异步数据完成后
            this.once = true;
        },
    },
};
</script>

<style lang="less" scoped>
.list {
    text-align: left;
    & > div:not(.load) {
        display: flex;
        margin: 40px 0;
        padding: 10px 30px;
        height: 150px;
        cursor: pointer;
        & > img {
            width: 200px;
            height: 100%;
            object-fit: contain;
            margin-right: 10px;
            transition: 0.5s;
        }
        & > div {
            flex: 1;
            display: flex;
            flex-direction: column;
            .title {
                font-size: 20px;
                margin-bottom: 10px;
                font-weight: bold;
            }
            .intro {
                flex: 1;
            }
            .info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: grey;
                & > div {
                    display: flex;
                    align-items: center;
                    .yan {
                        overflow: hidden;
                        display: inline-block;
                        width: 30px;
                        height: 30px;
                        position: relative;
                        & > img {
                            width: 1000px;
                            height: 1500px;
                            position: absolute;
                            top: -51px;
                            right: -309px;
                        }
                    }
                }
            }
        }
        &:hover {
            border-radius: 20px;
            box-shadow: 1px 1px 10px 10px #eee;
            & > img {
                transform: scale(1.05);
            }
            .title {
                color: #7781f1;
            }
        }
    }
    .load {
        text-align: center;
    }
    .active {
        border-radius: 20px;
        box-shadow: 1px 1px 10px 10px #eee;
        .title {
            color: #7781f1;
        }
    }
}
.arrowRight {
    // display: none;
    // margin: 0 10px;
    margin-left: 4px;
    width: 16px;
    height: 16px;
    overflow: hidden;
    position: relative;
    img {
        position: absolute;
        top: -81px;
        left: -1131px;
        width: 1200px;
    }
}
</style>
