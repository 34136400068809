import { render, staticRenderFns } from "./ListPartners.vue?vue&type=template&id=cde2b11a&scoped=true&"
import script from "./ListPartners.vue?vue&type=script&lang=js&"
export * from "./ListPartners.vue?vue&type=script&lang=js&"
import style0 from "./ListPartners.vue?vue&type=style&index=0&id=cde2b11a&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cde2b11a",
  null
  
)

export default component.exports